<h5 class="m-b-10 ng-star-inserted">
    Accidental Request For OMD
</h5>

<ul class="breadcrumb p-0">
    <li class="breadcrumb-item"><a href="javascript:" [routerLink]="['/welcome']"><i class="feather icon-home"></i></a>
    </li>
    <li class="breadcrumb-item" *ngIf="entityType == 'PD'"><a>{{'otProductionPlan.list.production' |
            translate}}</a></li>
    <li class="breadcrumb-item" *ngIf="entityType == 'ID'"><a>{{'otProductionPlan.list.industrialDistillery' |
            translate}}</a></li>
    <li class="breadcrumb-item" *ngIf="entityType == 'MIXED'"><a>Mixed
            {{'otProductionPlan.list.distillery'| translate}} </a></li>
    <li class="breadcrumb-item"><a href="javascript:" routerLink="/{{url}}/omd/accidentalomd/list">Accidental Request
            For OMD</a></li>
    <li class="breadcrumb-item">{{addedit}}</li>
</ul>

<div class="row">
    <div class="col-xl-12">
        <form [formGroup]="accidentalRequestOmd" autocomplete="off">
            <div class="col-xl-12">
                <app-card cardTitle="Accidental Request For OMD Details" class="list-panel add-panel">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">Request Date</label>
                                <input type="date" class="form-control" formControlName="requestDate">
                                <!-- <input type="text" class="form-control" formControlName="requestDate"
                                [owlDateTimeTrigger]="dtPickerVUTDate" [owlDateTime]="dtPickerVUTDate"
                                onkeydown="event.preventDefault()" placeholder="MM/DD/YYYY HH:MM:SS">
                            <owl-date-time #dtPickerVUTDate></owl-date-time> -->
                            </div>
                            <div *ngIf="formSubmitted && accidentalRequestOmd.get('requestDate').errors || accidentalRequestOmd.get('requestDate').touched "
                                class="error-msg">
                                <span *ngIf="accidentalRequestOmd.get('requestDate').errors?.required"> Request Date is
                                    Required</span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <label class="mandatory">PD25 OMD Transport Pass No</label>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" formControlName="omdPassNo" [attr.disabled]="addedit === 'Edit' ? true : null ||  isQrCodeDisabled ? true : null"
                                    placeholder="Enter PD25 OMD Transport Pass No">
                                <div class="input-group-append">
                                    <button class="btn btn-primary" type="button" (click)="scanNumber()"
                                        [disabled]="!accidentalRequestOmd.value.omdPassNo">
                                        <i class="fas fa-qrcode"></i>
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="formSubmitted && accidentalRequestOmd.get('omdPassNo').errors || accidentalRequestOmd.get('omdPassNo').touched "
                                class="error-msg">
                                <span *ngIf="accidentalRequestOmd.get('omdPassNo').errors?.required">PD25 OMD Transport
                                    Pass No is Required</span>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">Transport Pass Issue Date</label>
                                <input type="text" class="form-control" formControlName="tpIssueDate" disabled
                                    placeholder="DD/MM/YYYY">
                            </div>
                            <!-- <div *ngIf="formSubmitted && accidentalRequestOmd.get('issueDate').errors || accidentalRequestOmd.get('issueDate').touched "
            class="error-msg">
           <span *ngIf="accidentalRequestOmd.get('issueDate').errors?.required">Transport Pass Issue Date is Required</span> -->
                            <!-- </div> -->
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">Valid Upto</label>
                                <input type="text" class="form-control" formControlName="validUpTo" disabled
                                    placeholder="DD/MM/YYYY HH:MM A">
                            </div>
                            <!-- <div *ngIf="formSubmitted && accidentalRequestOmd.get('validUpto').errors || accidentalRequestOmd.get('validUpto').touched "
            class="error-msg">
           <span *ngIf="accidentalRequestOmd.get('validUpto').errors?.required">Valid Upto is Required </span> -->
                        </div>
                        <!-- </div> -->
                    </div>
                </app-card>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-12">
                        <app-card cardTitle="Consignor Details" class="list-panel add-panel">
                            <div class="row">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <label class="w-100 view-label">License Type<span
                                            class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    {{omdPd25Details?.licenseType}}
                                </div>

                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <label class="w-100 view-label">{{'molassesDC.list.licenseNumber' | translate}}<span
                                            class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    {{omdPd25Details?.licenseNumber}}
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <label class="w-100 view-label">{{'molassesDC.list.dName' | translate}}<span
                                            class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    {{omdPd25Details?.unitName}}
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <label class="w-100 view-label">{{'molassesDC.add.dAddr' | translate}}<span
                                            class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    {{addressDetails?.address}}
                                </div>
                            </div>
                        </app-card>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <app-card cardTitle="{{'blendTransferReq.add.consigneeDetails' | translate}}"
                            class="list-panel add-panel">
                            <div class="row">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <label class="w-100 view-label">OMD Name
                                        <span class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    {{omdPd25Details?.pd26?.omdName}}
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <label class="w-100 view-label">OMD Registeration /
                                        {{'molassesDC.list.licenseNumber' | translate}}<span
                                            class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    {{omdPd25Details?.pd26?.omdLicenseNo}}
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <label class="w-100 view-label">OMD Address<span
                                            class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    {{omdPd25Details?.pd26?.omdAddress}}
                                </div>
                            </div>
                        </app-card>
                    </div>
                </div>
            </div>
            <div class="col-xl-12">
                <app-card cardTitle="Indent Details" class="list-panel add-panel">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <label class="w-100 view-label">Indent No<span class="float-right">:</span></label>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            {{omdPd25Details?.pd26?.indentNo}}
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <label class="w-100 view-label">
                                Indent Date
                                <span class="float-right">:</span></label>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            {{omdPd25Details?.pd26?.indentDate | date:'dd/MM/yyyy'}}
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <label class="w-100 view-label">
                                Denatured Fees (in Rs.)
                                <span class="float-right">:</span></label>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            {{omdPd25Details?.denaturedFee}}
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <label class="w-100 view-label">
                                Dispatch (License) Fee (in Rs.)
                                <span class="float-right">:</span></label>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            {{omdPd25Details?.orderForValue == 'OMD Within Up' ? omdPd25Details?.totalFee : 0}}
                        </div>
                    </div>
                </app-card>
            </div>
            <div class="col-xl-12">
                <app-card cardTitle="Accidental Details" class="list-panel add-panel">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">Accidental Date</label>
                                <input type="date" class="form-control" formControlName="accidentalDate">
                                <!-- <input type="text" class="form-control" formControlName="accidentalDate"
                                [owlDateTimeTrigger]="dtAccidentalDate" [owlDateTime]="dtAccidentalDate"
                                onkeydown="event.preventDefault()" placeholder="MM/DD/YYYY HH:MM:SS">
                            <owl-date-time #dtAccidentalDate></owl-date-time> -->
                            </div>
                            <div *ngIf="formSubmitted && accidentalRequestOmd.get('accidentalDate').errors || accidentalRequestOmd.get('accidentalDate').touched "
                                class="error-msg">
                                <span *ngIf="accidentalRequestOmd.get('accidentalDate').errors?.required">Accidental
                                    Date is Required</span>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <label class="mandatory">Accidental Domain</label>
                            <div class="input-group mb-3">
                                <select class="form-control" formControlName="accidentalDomainCode"
                                    (change)="getWorkflowData($event.target.value)">
                                    <option value="">{{'language.select' | translate}}</option>
                                    <option value="WITHIN_UP">Within Up</option>
                                    <!-- <option value="OUTSIDE_UP">Outside Up</option> -->
                                </select>
                            </div>
                            <div *ngIf="formSubmitted && accidentalRequestOmd.get('accidentalDomainCode').errors || accidentalRequestOmd.get('accidentalDomainCode').touched "
                                class="error-msg">
                                <span
                                    *ngIf="accidentalRequestOmd.get('accidentalDomainCode').errors?.required">Accidental
                                    Domain is Required</span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <label class="mandatory">Accidental Location (District)</label>
                            <div class="input-group mb-3">
                                <select class="form-control" formControlName="accidentalDistrictCode">
                                    <option value="">{{'language.select' | translate}}</option>
                                    <option *ngFor="let tank of districtData" value="{{tank.code}}">
                                        {{tank.name}}</option>
                                </select>
                            </div>
                            <div *ngIf="formSubmitted && accidentalRequestOmd.get('accidentalDistrictCode').errors || accidentalRequestOmd.get('accidentalDistrictCode').touched "
                                class="error-msg">
                                <span
                                    *ngIf="accidentalRequestOmd.get('accidentalDistrictCode').errors?.required">Accidental
                                    Location (District) is Required</span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="form-group">
                                <label class="mandatory">Accidental Location Address</label>
                                <input type="text" class="form-control" formControlName="accidentalAddress"
                                    placeholder="Enter Accidental Location Address" maxlength="500">
                            </div>
                            <div *ngIf="formSubmitted && accidentalRequestOmd.get('accidentalAddress').errors || accidentalRequestOmd.get('accidentalAddress').touched "
                                class="error-msg">
                                <span *ngIf="accidentalRequestOmd.get('accidentalAddress').errors?.required">Accidental
                                    Location Address is Required</span>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">User Remarks</label>
                                <input type="text" class="form-control" maxlength="500" formControlName="userRemarks" placeholder="Enter Remarks">
                            </div>
                            <div *ngIf="formSubmitted && accidentalRequestOmd.get('userRemarks').errors || accidentalRequestOmd.get('userRemarks').touched "
                                class="error-msg">
                                <span *ngIf="accidentalRequestOmd.get('userRemarks').errors?.required">Remarks is
                                    Required</span>
                            </div>
                        </div>
                        <!-- </div> -->
                    </div>
                </app-card>
                <app-card cardTitle="{{'language.remarks' | translate}}" *ngIf="isSendback"
                class="list-panel add-panel">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">{{'language.remarks' | translate}}
                            </label>
                            <input type="text" class="form-control" formControlName="comments"
                                placeholder="Enter Remarks">
                            <app-show-errors eleName="Remarks" [frmSub]="formSubmitted"
                                [control]="getFormCntr('comments')">
                            </app-show-errors>
                        </div>
                    </div>
                </div>
            </app-card>
            </div>
        </form>
    </div>

    <div class="col-12 text-right mb-4">
        <!-- <button type="button" class="btn btn-primary ml-2 mb-2" (click)="preview(addFormGroup,'DRAFT', true)">Preview</button> -->

        <button type="button" class="btn btn-warning ml-2 mb-2" *ngIf="!isSendback"
            (click)="save(accidentalRequestOmd,'DRAFT')">{{'brewery.common.draft' |
            translate }}</button>
        <button type="button" class="btn btn-success ml-2 mb-2"
            (click)="save(accidentalRequestOmd,'SUBMITTED')">{{'brewery.common.submit' | translate }}</button>
        <button type="button" class="btn btn-secondary ml-2 mb-2" routerLink="/{{url}}/omd/accidentalomd/list"
            routerLinkActive="router-link-active">{{'brewery.common.cancel' | translate }}</button>
    </div>
</div>