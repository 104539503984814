import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { apiUrls } from "@appEnv/apiurls";
import { environment } from "@appEnv/environment";
import { _throw } from "rxjs/observable/throw";
import { LicenceManagementService } from "./license-managment.service";
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class CustomScmService {
  private readonly SCM_GETALLOCATED_LICENSENUMBER = "SCM_GETALLOCATED_LICENSENUMBER";
  private readonly SCM_SETENTITYTYPEFOROFFICER = "SCM_SETENTITYTYPEFOROFFICER";
  private readonly SCM_SUBUSER_ADD_ACCESS = "SCM_SUBUSER_ADD_ACCESS";
  authToken: any;
  private baseURL = environment.baseURLUser;
  //user and licensenumber api call added
  constructor(private http: HttpClient,  private datepipe: DatePipe,
    private licenseSer: LicenceManagementService) {}
  async getvalue() { 
    let value = await this.getUserdetailsbyid(localStorage.getItem("USER_ID")); 
    if (value.responseCode == 200) { 
      if (value.content.parentUserId) {
        //let getparentdetailsrole = await this.getparentdetailsrole();
        let getrolevalue = await this.getrolesbasedonsubuser(); 
        if(getrolevalue.content &&getrolevalue.responseCode==200 ){
          let containsEditValue = this.containsEdit(getrolevalue.content); 
          this.getparentdetailsid(containsEditValue) 
        } 
       
      }
      else{
        this.getparentdetailsid(true)
      }
    } 
    let officers= ['AECBREWERY','INSPECTORBREWERY','AECDISTILLERY','INSPECTORDISTILLERY','SUBINSSUG','VINTINERY_INS']; //selected officer mapped license number 
    if (localStorage.getItem("IS_CUSTOMER") == "false" &&   officers.includes(localStorage.getItem("DESIGNATION").toUpperCase())  ) {
      this.setenitytypeforofficer(true);
      let getlicensedetails = await this.getuserallocatedunit();
      if (getlicensedetails.responseCode == 200) { 
        let licensedetailsarray = getlicensedetails.content.map(item => ({licenseNumber: item.licenseNumber, licenseSubCategory: item.licenseSubCategory})); 
        this.setAllocatedlicensenumber(licensedetailsarray );
      }
    }
  } 

  containsEdit(obj) {
    for (let key in obj) {
      if(key.includes("EDIT")){
        return true;
      }
    }
    return false;
}
  setAllocatedlicensenumber(obj) {
    return localStorage.setItem(
      this.SCM_GETALLOCATED_LICENSENUMBER,
      JSON.stringify(obj)
    );
  }
  getparentdetailsid(obj) {
    return localStorage.setItem(this.SCM_SUBUSER_ADD_ACCESS, JSON.stringify(obj));
  }
setenitytypeforofficer(obj) {
    return localStorage.setItem(this.SCM_SETENTITYTYPEFOROFFICER, JSON.stringify(obj));
  }
  getuserallocatedunit(): any {
    const url = this.baseURL + "user/findUserAllocatedUnit";
    return this.http.get(url).toPromise();
  }
  getparentdetailsrole(): any {
    const url = this.baseURL + "roleMaster/findActiveRolesByUserId";
    return this.http.get(url).toPromise();
  }

  getUserdetailsbyid(userId): any {
    const url = this.baseURL + apiUrls.getCustomerById + "?id=" + userId;
    return this.http.get(url).toPromise();
  }
  getrolesbasedonsubuser(): any {
    const url = this.baseURL + 'roleMaster/findActiveRolesByUserId';
    return this.http.get(url).toPromise();
  }
  generateapplicationnumber(registrationType, entityType){
    let date = new Date()
    let year: number = date.getFullYear();
    let month: number = date.getMonth() + 1;
    let day: number = date.getDate();
    let hours: number = date.getHours();
    let minutes: number = date.getMinutes();
    let seconds: number = date.getSeconds();  
    return localStorage.getItem('userId')+ '/' + registrationType + '/' + entityType + '/' + year + month + day + hours + minutes + seconds;
  }
  OTHER_SOURCE_SYSTEM_EXIST_LOCAL()
  {
    if(localStorage.getItem('OTHER_SOURCE_SYSTEM')){
      return localStorage.getItem('OTHER_SOURCE_SYSTEM')
    }
    else{
      return '';
    } 
  }

  async backbuttonurlfetch(){
    let value:any=await this.licenseSer.redirectNMPagetopromise(localStorage.getItem('OTHER_APP_NUM')) 
      return value.content
  }
//Brand and label next year validation based on license expiry date
  nextyearbuttonbasedonlicense(details) {  
    if(details) {
    // Get the current year and expiry year 
    const dt = new Date();
    let currentYear: any = dt.getFullYear(); 
    if (dt.getMonth() == 0 || dt.getMonth() == 1 || dt.getMonth() == 2) {
      currentYear = currentYear;
    } else {
      currentYear = currentYear+1;
    } 
    let expiryYear = new Date(details.licenseExpiryDate) .getFullYear(); 
    // if(expiryYear==currentYear){
    //   return 'RF001'
    // }
    // if (expiryYear > currentYear ) {
    //   return 'RF002'
    // }
    // else{return 'RF001'}  
    return '';  
   }
  }
}
